<template>
    <div>
        
        <div class="content content_top_margin" style="min-height: 175px;">
            <div class="content_inner  ">
                <div class="full_width ">
                    <div class="full_width_inner">

                        <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1636151250857 grid_section"
                            style=" text-align:left;">
                            <div class=" section_inner clearfix">
                                <div class="section_inner_margin clearfix">
                                    <div class="wpb_column vc_column_container vc_col-sm-12">
                                        <div class="vc_column-inner">
                                            <div class="wpb_wrapper">
                                                <div class="vc_row wpb_row section vc_row-fluid vc_inner  single_top_row"
                                                    style=" text-align:left;">
                                                    <div class=" full_section_inner clearfix">
                                                        <div class="wpb_column vc_column_container vc_col-sm-6">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <div class="wpb_wrapper">
                                                                            <h1>Hybrid working<br>
                                                                                Cafe (or park, library, etc.)</h1>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-6">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <div class="wpb_wrapper">
                                                                            <p>
                                                                                 <router-link :to="{ name: 'managing-your-schedule-a45' }">
                                                                                    <em>Up
                                                                                    Next: </em> Managing Your
                                                                                    Schedule <img
                                                                                    class="vc_single_image-img attachment-full"
                                                                                    src="../../assets/images/airbnb/Airbnb-Arrows-2.png"
                                                                                    alt="" width="64"
                                                                                    height="37">
                                                                                 </router-link>
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 52px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>

                                                <div class="vc_row wpb_row section vc_row-fluid vc_inner  vc_custom_1637628101137"
                                                    style=" text-align:left;">
                                                    <div class=" full_section_inner clearfix">
                                                        <div class="wpb_column vc_column_container vc_col-sm-12">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 52px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>


                                                <div class="wpb_text_column wpb_content_element ">
                                                    <div class="wpb_wrapper">
                                                        <div class="heading_wrapper">
                                                            <h2>Ergo in a flash</h2>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 32px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>


                                                <div
                                                    class="wpb_text_column wpb_content_element  no-margin vc_custom_1637271963864">
                                                    <div class="wpb_wrapper">
                                                        <p>When you head to the cafe to do some work, don’t forget to
                                                            grab your laptop riser, external keyboard and mouse, so that
                                                            you can set yourself up well pretty much anywhere.</p>
                                                        <p>&nbsp;</p>
                                                        <p>If the height of the cafe table allows for your keyboard and
                                                            mouse to be at elbow level, then make sure to prop your
                                                            laptop up on your riser to avoid hunching. If the table is
                                                            high, and you find yourself hunching your shoulders, try
                                                            using your lap for your keyboard and mouse, and using your
                                                            notebook as a surface.</p>

                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 32px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>


                                                <div class="wpb_text_column wpb_content_element ">
                                                    <div class="wpb_wrapper">
                                                        <h2>Awkward</h2>
                                                        <p>If you find yourself working in less than an ergo-ideal
                                                            situation, limit your work stretches to no more than 30
                                                            minutes at a time. To combat any awkward posture, take a
                                                            short break, stretch and then get back to it.</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       <div class="vc_row wpb_row section vc_row-fluid  grid_section" style=" text-align:left;">
                            <div class=" section_inner clearfix">
                                <div class="section_inner_margin clearfix">
                                    <div class="wpb_column vc_column_container vc_col-sm-12">
                                        <div class="vc_column-inner">
                                            <div class="wpb_wrapper">
                                                <div class="wpb_text_column wpb_content_element ">
                                                    <div class="wpb_wrapper">
                                                        <h2>Continue your work from anywhere journey</h2>

                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 32px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>

                                                <div class="vc_row wpb_row section vc_row-fluid vc_inner  continue_block"
                                                    style=" text-align:left;">
                                                    <div class=" full_section_inner clearfix">
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                            <router-link :to="{ name: 'hybrid-working-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="750" height="500"
                                                                                        src="../../assets/images/airbnb/Airbnb-Hybrid.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="Airbnb---Hybrid"
                                                                                        >
                                                                                </div>
                                                                            </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'hybrid-working-a45' }">
                                                                        <div class="wpb_wrapper">
                                                                            <h4>Hybrid Working</h4>
                                                                            <p>Shuttling between the office and home?
                                                                                We’ve got you covered.</p>
                                                                        </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                             <router-link :to="{ name: 'staying-focused-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="750" height="500"
                                                                                        src="../../assets/images/airbnb/Airbnb-Hybrid-Staying-Focused.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="Airbnb-Hybrid-Staying-Focused"
                                                                                        >
                                                                                </div>
                                                                             </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'staying-focused-a45' }">
                                                                        <div class="wpb_wrapper">
                                                                            <h4>Staying Focused</h4>
                                                                            <p>Staying focused requires skill and
                                                                                practice. Learn how to stay in the zone.
                                                                            </p>

                                                                        </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                            <router-link :to="{ name: 'mobility-kits-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="750" height="500"
                                                                                        src="../../assets/images/airbnb/Airbnb-Hybrid-Mobility-Kits.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="Airbnb---Hybrid---Mobility-Kits"
                                                                                        >
                                                                                </div>
                                                                            </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'mobility-kits-a45' }">
                                                                            <div class="wpb_wrapper">
                                                                                <h4>Mobility kits</h4>
                                                                                <p>With the right tools, it’s easy to stay
                                                                                    comfortable and productive wherever you
                                                                                    work.</p>
                                                                            </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                            <router-link :to="{ name: 'working-from-the-cafe-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="2560" height="1002"
                                                                                        src="../../assets/images/airbnb/REPLACEMENT-WORK-IN-CAFE-PARK-ETC2-scaled.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="REPLACEMENT - WORK IN CAFE, PARK ETC2"
                                                                                        >
                                                                                </div>
                                                                            </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'working-from-the-cafe-a45' }">
                                                                            <div class="wpb_wrapper">
                                                                                <h4>Working From the Cafe</h4>
                                                                                <p>Working from the cafe? Ergo has your back
                                                                                    while you get caffeinated.</p>
                                                                            </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                            <router-link :to="{ name: 'managing-your-schedule-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="750" height="502"
                                                                                        src="../../assets/images/airbnb/Airbnb-I-am-Hybrid-managing-schedule.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="Airbnb---I-am-Hybrid---managing-schedule"
                                                                                        >
                                                                                </div>
                                                                            </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'managing-your-schedule-a45' }">
                                                                            <div class="wpb_wrapper">
                                                                                <h4>Manage your schedule</h4>
                                                                                <p>Discover how to get your schedule under
                                                                                    control in three easy steps.</p>
                                                                            </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                             <router-link :to="{ name: 'work-anywhere-stretches-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="750" height="499"
                                                                                        src="../../assets/images/airbnb/Airbnb-Hybrid-Footer-Work-Anywhere-Stretches.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="Airbnb-Hybrid-Footer---Work-Anywhere-Stretches"
                                                                                        >
                                                                                </div>
                                                                             </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'work-anywhere-stretches-a45' }">
                                                                        <div class="wpb_wrapper">
                                                                            <h4>Work Anywhere Stretches</h4>
                                                                            <p>Where you go, you take yourself with you.
                                                                                Be kind to your body.</p>

                                                                        </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 32px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="vc_row wpb_row section vc_row-fluid " style=" text-align:left;">
                            <div class=" full_section_inner clearfix">
                                <div class="wpb_column vc_column_container vc_col-sm-12">
                                    <div class="vc_column-inner">
                                        <div class="wpb_wrapper">
                                            <div class="wpb_raw_code wpb_content_element wpb_raw_html">
                                                <div class="wpb_wrapper">
                                                    <div id="headerPopup" class="mfp-hide"
                                                        style="padding:56.25% 0 0 0;position:relative;"><iframe
                                                            src="https://player.vimeo.com/video/580346529?h=3561a2038c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                                            frameborder="0"
                                                            allow="autoplay; fullscreen; picture-in-picture"
                                                            allowfullscreen=""
                                                            style="position:absolute;top:0;left:0;width:100%;height:100%;"
                                                            title="ERGO_in 30 seconds"></iframe></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->



                    </div>
                </div>
              

            </div>
        </div>

    </div>
</template>
<script>
    //import carousel from "vue-owl-carousel"
    export default {
        name: 'working-from-the-cafe-a45',
        mounted(){
			document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
		},
    }
</script>